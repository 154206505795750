import React, { useState, useEffect } from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  FaDownload,
  FaEye,
  FaStar,
} from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from '../../xFireBase/AuthContext';

const AppsCard = ({ All_appData, key }) => {

  const { CurrentLanguage, darkMode } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isTooSmall = useMediaQuery("(max-width:359px)");

  const [current_Item_Data, setCurrent_Item_Data] = useState({
    Item_Name: '',
    Item_Picture: '',
    Item_Size: '',
    Item_Downloaded_Times: 0,
    Item_watched_times: 0,
    Item_Reviews: 0,
  });

  const formatNumber = (number) => {
    if (number > 99999) {
      return (number / 1000).toFixed(1) + 'K';
    }
    return number?.toLocaleString();
  };


  useEffect(() => {
    async function fetchData() {
      setCurrent_Item_Data({
        Item_Name: All_appData?.app_Name,
        Item_Picture: All_appData?.app_Picture[0],
        Item_Size: All_appData?.app_Size,
        Item_Downloaded_Times: await formatNumber(All_appData?.app_Downloaded_Times),
        Item_watched_times: await formatNumber(All_appData?.app_watched_times?.watched_By?.length),
        Item_Reviews: await formatNumber(All_appData?.app_Reviews),
      });
    }
    fetchData();
  }, [All_appData]);

  return (
    <div
      className={darkMode === 'light' ? 'games-card light' : 'games-card dark'}
      style={{
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        minHeight: isNonMobile ? '244px' : '230px',
      }}>
      <div className="games-imgbx" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <span className='RETO_watermark' style={{
          fontSize: isNonMobile ? '0.8rem' : '0.53rem',
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          opacity: '0.65',
          padding: '0.2rem',
          borderRadius: '0.2rem',
          position: 'absolute',
          top: isNonMobile
            ? '0.2rem'
            :
            (isNonMobile === false
              && current_Item_Data?.Item_Picture.height > current_Item_Data?.Item_Picture.width
            )
              ? '1rem'
              : '0.1rem',

          left: isNonMobile ? '0.15rem' : '0.4rem',
        }}>
          Arab2Games.com
        </span>
        <LazyLoadImage
          src={current_Item_Data?.Item_Picture}
          effect="blur"
          alt='app_Picture'
          draggable={false}
          style={{ width: '100%', height: '100%', maxHeight: '180px', maxWidth: '275px', justifyContent: 'center', alignItems: 'center' }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      <div>
        <h4 style={{
          fontSize: '0.95rem',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          textAlign: 'left',
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
          direction: CurrentLanguage === 'EN' ? null : 'ltr',
          color: darkMode === 'light' ? '#000000' : '#ffffff',
        }}>
          {current_Item_Data?.Item_Name?.length >= 35 ? `${current_Item_Data?.Item_Name?.slice(0, 35)}...` : current_Item_Data?.Item_Name}
        </h4>

        {!isNonMobile && (
          <div style={{
            flexDirection: 'row',
            display: 'flex',
            color: darkMode === 'light' ? '#2C2C2C' : '#969595',
            fontSize: '0.75rem',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px',
            textAlign: 'center',
            marginLeft: '0.8rem',
            marginRight: '0.8rem',
            direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
            width: 'auto',
            height: 'auto'
          }}>
            <div style={{
              fontSize: '0.79rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
              display: 'flex',
              flexDirection: (current_Item_Data?.Item_watched_times > 9999 && current_Item_Data?.Item_Downloaded_Times > 999)
                ? 'column'
                : isTooSmall ? 'column' : 'row'
            }}>
              {current_Item_Data?.Item_Downloaded_Times}
              <FaDownload style={{
                color: darkMode === 'light' ? '#9642F7' : '#B67CF8AF',
                marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                marginRight: CurrentLanguage === 'EN' ? '3px' : ''
              }}
              />
            </div>

            <div style={{
              fontSize: '0.79rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
              display: 'flex',
              flexDirection: (current_Item_Data?.Item_Downloaded_Times > 9999 && current_Item_Data?.Item_watched_times > 999) ||
                (current_Item_Data?.Item_watched_times > 9999 && current_Item_Data?.Item_Downloaded_Times > 999)
                ? 'column'
                : isTooSmall ? 'column' : 'row'
            }}>
              {current_Item_Data?.Item_watched_times}
              <FaEye
                style={{
                  color: darkMode === 'light' ? '#D82C2CEF' : '#DF6868D5',
                  marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                  marginRight: CurrentLanguage === 'EN' ? '3px' : ''
                }}
              />
            </div>

            <div style={{
              fontSize: '0.79rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
              display: 'flex',
              flexDirection: (current_Item_Data?.Item_Downloaded_Times > 9999
                && current_Item_Data?.Item_watched_times > 999) ||
                (current_Item_Data?.Item_watched_times > 9999 && current_Item_Data?.Item_Downloaded_Times > 999)
                ? 'column'
                : isTooSmall ? 'column' : 'row'
            }}>
              {current_Item_Data?.Item_Reviews}
              <FaStar
                style={{
                  color: darkMode === 'light' ? '#F7A52BFA' : '#FFB547EA',
                  marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                  marginRight: CurrentLanguage === 'EN' ? '3px' : ''
                }}
              />
            </div>
          </div>
        )}

        {isNonMobile && (
          <div style={{
            flexDirection: 'row',
            display: 'flex',
            color: '#969595',
            fontSize: '0.79rem',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
            marginTop: '5px',
            marginBottom: '15px',
            width: 'auto',
            color: darkMode === 'light' ? '#2C2C2C' : '#969595',
            height: 'auto',
            marginLeft: isNonMobile ? (CurrentLanguage === 'EN' ? '' : '0.5rem') : (CurrentLanguage === 'EN' ? '' : ''),
            marginRight: isNonMobile ? (CurrentLanguage === 'EN' ? '0.5rem' : '') : (CurrentLanguage === 'EN' ? '' : ''),
          }}>
            <div style={{
              fontSize: '0.89rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr'
            }}>
              {current_Item_Data?.Item_Downloaded_Times}
              <FaDownload style={{
                color: darkMode === 'light' ? '#9642F7' : '#B67CF8AF',
                marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                marginRight: CurrentLanguage === 'EN' ? '3px' : ''
              }}
              />
            </div>

            <div style={{
              fontSize: '0.89rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginLeft: '1.5rem',
              marginRight: '1.5rem',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr'
            }}>
              {current_Item_Data?.Item_watched_times}
              <FaEye
                style={{
                  color: darkMode === 'light' ? '#D82C2CEF' : '#DF6868D5',
                  marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                  marginRight: CurrentLanguage === 'EN' ? '3px' : ''
                }}
              />
            </div>

            <div style={{
              fontSize: '0.89rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr'
            }}>
              {current_Item_Data?.Item_Reviews}
              <FaStar
                style={{
                  color: darkMode === 'light' ? '#F7A52BFA' : '#FFB547EA',
                  marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                  marginRight: CurrentLanguage === 'EN' ? '3px' : ''
                }}
              />
            </div>
          </div>
        )}

        <div style={{
          flexDirection: 'row',
          display: 'flex',
          color: '#969595',
          fontSize: '0.75rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
          marginLeft: '0.8rem',
          marginRight: '0.8rem',
          direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
          width: 'auto',
          height: 'auto'
        }}>
          <div style={{
            flexDirection: 'row',
            display: 'flex',
            color: darkMode === 'light' ? '#2C2C2C' : '#969595',
            direction: isNonMobile ? (CurrentLanguage === 'EN' ? 'rtl' : 'ltr') : (CurrentLanguage === 'EN' ? 'ltr' : 'rtl')
          }}>
            <h6 style={{
              fontSize: '0.79rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginLeft: isNonMobile ? (CurrentLanguage === 'EN' ? '' : '0.5rem') : '',
              marginTop: isNonMobile ? (CurrentLanguage === 'EN' ? '1.5px' : '2.5px') : '1.5px',
              direction: 'ltr'
            }}>
              {isNonMobile ?
                current_Item_Data?.Item_Size
                : (CurrentLanguage === 'EN' ? 'Size :' : ': بحجم')
              }
            </h6>
            <h6 style={{
              fontSize: isNonMobile ? '0.95rem' : '0.75rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginLeft: isNonMobile ? (CurrentLanguage === 'EN' ? '' : '0.35rem') : (CurrentLanguage === 'EN' ? '0.35rem' : '0.35rem'),
              marginRight: '0.5rem',
              direction: !isNonMobile ? (CurrentLanguage === 'EN' ? 'ltr' : 'ltr') : '',
              marginTop: isNonMobile ? null : (CurrentLanguage === 'EN' ? '1.5px' : '2.5px')
            }}>
              {!isNonMobile ?
                current_Item_Data?.Item_Size
                : (CurrentLanguage === 'EN' ? ': Size' : ': بحجم')
              }
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppsCard;