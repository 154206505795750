import React, { useEffect, useState } from 'react';
import Profile_Loading from '../../../assets/img/icons/Profile_Loading.svg'
import { firebase, database } from '../../../xFireBase/Storage';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button, } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { TextField } from '@mui/material';
import TrackVisibility from 'react-on-screen';
import { FaSearch } from 'react-icons/fa';
import { ArrowLeftRight, Trash3 } from 'react-bootstrap-icons';
import { useAuth } from '../../../xFireBase/AuthContext';
import processDescription from '../../../hooks/processDescription';


const DownloadPage = () => {

  const { CurrentLanguage, darkMode } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:992px)");
  const isTablet = useMediaQuery("(min-width:525px)");
  const [User_Downloads, setUser_Downloads] = useState([]);

  const [gamesWrapper, setGamesWrapper] = useState(12);
  const [LoadMoreGames, setLoadMoreGames] = useState(false);
  const [Stop_LoadMoreGames, setStop_LoadMoreGames] = useState(false);
  const [SearchValue, setSearchValue] = useState("");
  const [start_search, setStart_search] = useState(false);
  const [error_search, setError_search] = useState('');

  const [CurrentDownloadID, setCurrentDownloadID] = useState('');
  const [preparedToDelete_ALL, setPreparedToDelete_ALL] = useState(false);
  const [preparedToDelete, setPreparedToDelete] = useState(false);
  const [goToPage, setgoToPage] = useState(false);
  const [CurrentgoToPage, setCurrentgoToPage] = useState('');

  const [box_isHovered, setBox_isHovered] = useState(false);


  const LoadingMoreGames = () => {
    setLoadMoreGames(true)

    setTimeout(() => {
      setStop_LoadMoreGames(true)
    }, 1100)
    setTimeout(() => {
      setLoadMoreGames(false)
      setStop_LoadMoreGames(false)
      setGamesWrapper(gamesWrapper + 12);
    }, 1200)
  }


  useEffect(() => {
    database
      .collection('users')
      .doc(firebase.auth().currentUser.email)
      .collection('more-details')
      .doc('User_History')
      .collection('User_Downloads')
      .orderBy('Download_CreatedAt', 'desc')
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setUser_Downloads(data ? data : []);

        }
      })
  }, [])

  useEffect(() => {
    if (SearchValue.length > 0 && start_search === false) {
      setStart_search(true);
    }

  }, [SearchValue, start_search])

  useEffect(() => {
    if (User_Downloads.length <= 0 && SearchValue?.length > 0) {
      setError_search('لا يوجد نتائج للبحث');
    }
    else if (User_Downloads.length <= 0) {
      setError_search('حدث خطأ ما، يرجى المحاولة مرة أخرى او تحديث الصفحة');
    }
    else {
      setError_search('');
    }
  }, [User_Downloads, SearchValue])

  useEffect(() => {
    if (SearchValue.length > 0 && User_Downloads.length > 0 && SearchValue !== "" && start_search === true) {
      setTimeout(() => {
        setStart_search(false);
      }, 2000);
    } else if (SearchValue.length === 0 || SearchValue === "" || User_Downloads.length === 0) {
      setStart_search(false);
    }
  }, [SearchValue, User_Downloads, start_search])

  return (
    <div id='DonwloadPage'>
      <div
        style={{
          textDecoration: 'none', color: 'white', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', alignItems: 'flex-start',
          justifyContent: 'flex-end', textAlign: 'right', margin: '0.5rem',
          display: 'flex', flexDirection: isNonMobile ? 'row' : 'column', width: '100%',
        }}
      >
        {/** the box on the left */}
        <div className='container'>
          <div
            size={12}
            xs={6}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            className={isNonMobile ? 'AccountEdit-bx' : 'AccountEdit-bx-Mobile'}
            style={{
              boxShadow: box_isHovered === true ? '0 0 20px 0 #42297c'
                : darkMode === 'light'
                  ? '0 0 20px 0 #14141488'
                  : '0 0 20px 0 #e2dada1a'
            }}
            onMouseEnter={() => setBox_isHovered(true)}
            onMouseLeave={() => setBox_isHovered(false)}
          >

            <div className='AccountEdit-bx-Container-content'>
              <header style={{
                paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: isNonMobile || isTablet ? 'row' : 'column', justifyContent: 'space-between',
                alignItems: isNonMobile || isTablet ? 'center' : 'flex-start', textAlign: 'right', width: '100%', padding: '0.5rem',
                direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
              }}>
                <h2 style={{
                  fontSize: '1.25rem',
                  color: darkMode === 'light' ? '#000000' : 'white',
                  borderRadius: '0.2rem',
                }}
                >
                  <span style={{ color: '#ff434c' }}>|</span> {CurrentLanguage === 'EN' ? 'Download History' : 'سجل التحميلات'}
                  <span
                    style={{
                      color: '#ff434c', fontSize: '1.25rem', borderRadius: '0.2rem', padding: '5px', margin: '0 5px'
                    }}
                  >
                    {' '}{User_Downloads.length.toLocaleString()}
                  </span>
                </h2>
                <div style={{
                  direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', width: '100%', maxWidth: '330px', marginLeft: CurrentLanguage === 'EN' ? null : '20px',
                  height: '100%', justifyContent: "center", alignItems: "center", textAlign: 'center', display: 'flex', flexDirection: 'row', margin: '20px 0'
                }}>
                  <a
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      maxWidth: '330px',
                      height: '100%',
                      padding: '10px',
                      borderRadius: '25px',
                      border: 'none',
                      outline: 'none',
                      fontSize: '1.2rem',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: darkMode === 'light' ? '#EEECEC' : '#F5F5F52A',
                    }
                    }
                  >
                    <a

                      style={{
                        color: darkMode === 'light' ? '#000000' : '#ffffff', 
                        fontSize: '1.2rem', 
                        marginLeft: CurrentLanguage === 'EN' ? null : '15px',
                        marginRight: CurrentLanguage === 'EN' ? '15px' : null,
                      }}
                    >
                      <FaSearch />
                    </a>

                    <input
                      variant="filled"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // do nothing and don't refresh the page
                          e.preventDefault();
                        }
                      }}
                      name="SearchValue"
                      label={CurrentLanguage === 'EN' ? "search" : "البحث"}
                      placeholder={CurrentLanguage === 'EN'
                        ? 'Name / Downloaded Date / Size / Genres / Type / Number'
                        : 'اسم / تاريخ التحميل / الحجم / التصنيف / الرقم'}
                      value={SearchValue}
                      autoFocus={false}
                      onChange={(e) => {
                        // the value should not start with space
                        if (e.target.value.startsWith(" ")) {
                          return;
                        }
                        else {
                          setSearchValue(e.target.value);
                        }
                      }}
                      type="text"
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        outline: 'none',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '90%',
                        fontSize: '1.05rem',
                        color: darkMode === 'light' ? '#000000' : '#ffffff',
                        direction: 'ltr',
                        fontFamily: 'Cairo',
                        fontWeight: 'bold',
                        padding: '0',
                        margin: '0',
                      }}
                    />
                  </a>
                </div>
              </header>

              {User_Downloads.length > 0 &&
                <div style={{
                  paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: isNonMobile || isTablet ? 'row' : 'column', justifyContent: 'space-between',
                  alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                }}>
                  <Button
                    variant='contained'
                    onMouseEnter={() => {
                      setPreparedToDelete_ALL(true)
                    }}
                    onMouseLeave={() => {
                      setPreparedToDelete_ALL(false)
                    }}
                    style={{
                      color: 'white', borderRadius: '1rem',
                      backgroundColor: preparedToDelete_ALL === true ? '#BE0A0AAD' : '#3F3E3E49', marginBottom: '20px', justifyContent: 'center'
                    }}
                    onClick={() => {
                      // first alert to confirm the delete with YES or NO options
                      const confirmAlert = window.confirm(
                        CurrentLanguage === 'EN' ? 'Are you sure you want to delete all your downloads history ❓'
                          : '❓ هل أنت متأكد من حذف سجل التحميلات كامل ');

                      if (confirmAlert === true) {
                        // delete the game from the user downloads
                        setTimeout(() => {
                          database
                            .collection('users')
                            .doc(firebase.auth().currentUser.email)
                            .collection('more-details')
                            .doc('User_History')
                            .collection('User_Downloads')
                            .get()
                            .then((querySnapshot) => {
                              querySnapshot.forEach((doc) => {
                                doc.ref.delete();
                              });
                              setUser_Downloads([]);
                            })
                            .then(() => {
                              setTimeout(() => {
                                alert('تم حذف سجل التحميلات كامل ✅');
                              }, 350);
                            })
                            .catch((error) => {
                              console.error("Error removing document: ");
                            });
                        }, 500)
                      }
                      else {
                        setTimeout(() => {
                          alert('تم إلغاء عملية الحذف ❌');
                        }, 250);
                      }
                    }}>
                    <Trash3 style={{
                      marginLeft: CurrentLanguage === 'EN' ? null : '5px', marginRight: CurrentLanguage === 'EN' ? '5px' : null,
                      color: preparedToDelete_ALL === true ? '' : '#FA0F1B',
                      transition: 'all 0.2s ease-in-out'
                    }} />
                    <span style={{
                      fontSize: isNonMobile || isTablet ? '15px' : '13px',
                      color: darkMode === 'light' ? '#000000' : null,
                    }}
                    >
                      {CurrentLanguage === 'EN' ? 'Delete All Downloads History' : 'حذف سجل التحميلات كامل '}
                    </span>
                  </Button>
                </div>
              }

              <div style={{
                display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center',
                textAlign: 'center', width: '100%', padding: '0.5rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
              }}>
                {User_Downloads.length <= 0 ?
                  <div style={{
                    width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    textAlign: 'center', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                  }}>
                    <h2 style={{
                      fontSize: '1.2rem',
                      color: darkMode === 'light' ? '#000000' : '#ffffff',
                      borderRadius: '0.2rem'
                    }}
                    >{CurrentLanguage === 'EN' ? 'No Downloads' : 'لا يوجد تحميلات'}
                    </h2>
                  </div>
                  :
                  User_Downloads?.filter((Result) => {
                    if (SearchValue.length > 0) {

                      // if the games_search is empty return all the data, else return the data that includes the SearchValue
                      if (
                        Result?.Download_Name?.toLowerCase().includes(SearchValue.toLowerCase())
                        || Result?.Download_Name_alias?.toLowerCase()?.includes(SearchValue?.toLowerCase())
                        || Result?.Download_Number?.toString().includes(SearchValue.toLowerCase())
                        || `#${Result?.Download_Number?.toString()}`.includes(SearchValue.toLowerCase())
                        || Result?.Download_aType?.toLowerCase().includes(SearchValue.toLowerCase())
                        || Result?.Download_Downloaded_At_Date?.toLowerCase().includes(SearchValue.toLowerCase())
                        || Result?.Download_Size?.toLowerCase().includes(SearchValue.toLowerCase())
                        || Result?.Download_Genres?.map((item) => item.toLowerCase()).includes(SearchValue.toLowerCase())
                      ) {
                        return Result
                      }
                    }
                    else if (SearchValue.length <= 0) {
                      return Result
                    }
                  })
                    .slice(0, gamesWrapper)
                    .map((doc) => {
                      return (
                        <div
                          style={{
                            backgroundColor: '#7272720C', borderRadius: '15px', display: 'flex', flexDirection: 'column', marginBottom: '30px',
                            alignItems: 'center', textAlign: 'center', width: isNonMobile || isTablet ? '47%' : '100%', padding: '0.5rem',
                          }}
                        >

                          <div
                            style={{
                              width: '100%', display: 'flex', flexDirection: isNonMobile ? 'row' : 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                              textAlign: 'center', marginBottom: '30px',
                            }}>
                            {isNonMobile ?
                              <div style={{
                                width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end',
                                textAlign: 'center', direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr'
                              }}>
                                <div style={{
                                  width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end',
                                  textAlign: 'center', direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr'
                                }}>
                                  <LazyLoadImage
                                    src={doc.Download_Picture}
                                    alt={doc.Download_Name}
                                    effect="blur"
                                    style={{ width: '100%', height: '100%', maxHeight: '220px', borderRadius: '15px', }}
                                  />
                                  <div style={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', textAlign: 'center',
                                    width: '100%', height: '100%'
                                  }}>


                                    <div style={{ flexDirection: 'row', marginBottom: '28px', justifyContent: 'center', alignItems: 'center' }}>
                                      <Button
                                        variant='contained'
                                        onMouseEnter={() => {
                                          setCurrentgoToPage(doc.Download_ID)
                                          setgoToPage(true)
                                        }}
                                        onMouseLeave={() => {
                                          setCurrentgoToPage('')
                                          setgoToPage(false)
                                        }}
                                        style={{
                                          color: 'white', borderRadius: '1rem', marginRight: CurrentLanguage === 'EN' ? null : '50px',
                                          marginLeft: CurrentLanguage === 'EN' ? '50px' : null,
                                          backgroundColor: goToPage === true ? CurrentgoToPage === doc.Download_ID
                                            ? '#2759A5B6'
                                            : darkMode === 'light' ? '#2759A58C' : '#2759A549'
                                            : darkMode === 'light' ? '#2759A58C' : '#2759A549',
                                          justifyContent: 'center',
                                        }}
                                        onClick={() => {
                                          window.location.href = doc.Download_aType === 'app'
                                            ? `/A2G_apps/${doc.Download_Number}/${doc.Download_Page}`
                                            : doc.Download_aType === 'game' ? `/A2G_games/${doc.Download_Number}/${doc.Download_Page}`
                                              : null
                                        }}
                                      >
                                        {CurrentLanguage === 'EN'
                                          ? 'Go to Page'
                                          : 'الذهاب للصفحة'}
                                        <ArrowLeftRight style={{
                                          marginLeft: CurrentLanguage === 'EN' ? null : '5px', marginRight: CurrentLanguage === 'EN' ? '5px' : null,
                                          color: goToPage === true ? CurrentgoToPage === doc.Download_ID ? '' : '#FFFFFF' : '#FFFFFF',
                                          transition: 'all 0.2s ease-in-out'
                                        }} />
                                      </Button>

                                      <Button
                                        variant='contained'
                                        onMouseEnter={() => {
                                          setCurrentDownloadID(doc.Download_ID)
                                          setPreparedToDelete(true)
                                        }}
                                        onMouseLeave={() => {
                                          setCurrentDownloadID('')
                                          setPreparedToDelete(false)
                                        }}
                                        style={{
                                          color: darkMode === 'light' ? '#000000' : '#ffffff',
                                          borderRadius: '1rem',
                                          backgroundColor:
                                            preparedToDelete === true ? CurrentDownloadID === doc.Download_ID
                                              ? '#BE0A0AAD'
                                              : '#2C2C2C3A'
                                              : '#42424233',
                                          justifyContent: 'center',
                                        }}
                                        onClick={() => {
                                          // delete the game from the user downloads
                                          setTimeout(() => {
                                            database
                                              .collection('users')
                                              .doc(firebase.auth().currentUser.email)
                                              .collection('more-details')
                                              .doc('User_History')
                                              .collection('User_Downloads')
                                              .doc(doc.Download_ID)
                                              .delete()
                                              .then(() => {
                                                if (User_Downloads.length === 1) {
                                                  setUser_Downloads([]);
                                                }
                                              })
                                          }, 500)
                                        }}
                                      >
                                        {CurrentLanguage === 'EN' ? 'Remove' : 'حذف '}
                                        <Trash3 style={{
                                          marginLeft: CurrentLanguage === 'EN' ? null : '5px', marginRight: CurrentLanguage === 'EN' ? '5px' : null,
                                          color: preparedToDelete === true ? CurrentDownloadID === doc.Download_ID ? '' : '#FF3943' : '#FF3943',
                                          transition: 'all 0.2s ease-in-out'
                                        }} />
                                      </Button>

                                    </div>

                                    <h2 style={{
                                      fontSize: '1rem', color: '#3993B6', borderRadius: '0.2rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                                      display: 'flex', flexDirection: 'row', marginBottom: '12px'
                                    }}
                                    >
                                      <span style={{ color: darkMode === 'light' ? '#000000' : '#FFFFFF', margin: '0 10px' }}>
                                        {CurrentLanguage === 'EN'
                                          ? doc.Download_aType === 'game' ? 'Game : ' : doc.Download_aType === 'app' ? 'App : ' : null

                                          : doc.Download_aType === 'game' ? 'لعبة : ' : doc.Download_aType === 'app' ? 'برنامج : '
                                            : null
                                        }
                                      </span>
                                      <span style={{ direction: 'ltr', display: 'flex', flexDirection: 'row', fontSize: '15px' }}>
                                        {doc.Download_Name.length >= 33 ? `${doc.Download_Name.slice(0, 33)}...` : doc.Download_Name}
                                      </span>
                                    </h2>

                                    <h2 style={{
                                      fontSize: '1rem', color: '#3993B6', borderRadius: '0.2rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                                      display: 'flex', flexDirection: 'row', marginBottom: '12px'
                                    }}
                                    >
                                      <span style={{ color: darkMode === 'light' ? '#000000' : '#FFFFFF', margin: '0 10px' }}>
                                        {CurrentLanguage === 'EN'
                                          ? 'Size :'
                                          : 'الحجم :'
                                        }
                                      </span>
                                      <span style={{ direction: 'ltr', display: 'flex', flexDirection: 'row', fontSize: '15px' }}>
                                        {doc.Download_Size}
                                      </span>
                                    </h2>
                                  </div>

                                </div>

                                <div style={{ width: '100%', height: '100%' }}>
                                  <h2 style={{
                                    fontSize: '1rem', color: '#3993B6', borderRadius: '0.2rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                                    display: 'flex', flexDirection: 'row', margin: '12px 0'
                                  }}
                                  >
                                    <span style={{
                                      color: darkMode === 'light' ? '#000000' : '#FFFFFF', margin: '0 10px', fontSize: '15px'
                                    }}>
                                      {CurrentLanguage === 'EN'
                                        ? 'Downloaded At : '
                                        : 'تاريخ التحميل : '
                                      }
                                    </span>
                                    <span style={{
                                      fontSize: '14.5px', color: '#3993B6', borderRadius: '0.2rem', flexWrap: 'wrap', width: 'fit-content',
                                      direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', display: 'flex', flexDirection: 'row'
                                    }}
                                    >
                                      <span style={{ direction: 'ltr', display: 'flex', flexDirection: 'row' }}>
                                        {CurrentLanguage === 'EN'
                                          ? doc.Download_Downloaded_At_Date + ' - ' + doc.Download_Downloaded_At_Time
                                          : doc.Download_Downloaded_At_Time + ' - ' + doc.Download_Downloaded_At_Date
                                        }
                                      </span>
                                      <span>
                                        {doc.Download_Downloaded_At_Date && doc.Download_Downloaded_At_Time ?
                                          <span style={{
                                            color: darkMode === 'light' ? '#000000' : '#FFFFFF',
                                            fontSize: CurrentLanguage === 'EN' ? '15.5px' : '16.5px',
                                            margin: '0 5px',
                                          }}
                                          >
                                            {CurrentLanguage === 'EN'
                                              ? (Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)) === 0)
                                                ? ' ( Today )'
                                                : (Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)) === 1)
                                                  ? ' ( Yesterday )'
                                                  : '( ' + Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)).toLocaleString() + ' days ago)'


                                              : (Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)) === 0)
                                                ? ' ( اليوم )'
                                                : (Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)) === 1)
                                                  ? ' ( أمس )'
                                                  : ' ( منذ ' + Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)).toLocaleString() + ' يوم )'
                                            }
                                          </span>
                                          : null
                                        }
                                      </span>
                                    </span>
                                  </h2>

                                  <h2 style={{
                                    fontSize: '1rem', color: '#3993B6', borderRadius: '0.2rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                                    display: 'flex', flexDirection: 'row', marginBottom: '12px'
                                  }}
                                  >
                                    <span style={{ color: darkMode === 'light' ? '#000000' : '#FFFFFF', margin: '0 10px' }}>
                                      {CurrentLanguage === 'EN'
                                        ? doc.Download_aType === 'app' ? 'App Number : ' : doc.Download_aType === 'game' ? 'Game Number : ' : null
                                        : doc.Download_aType === 'app' ? 'رقم البرنامج : ' : doc.Download_aType === 'game' ? 'رقم اللعبة : ' : null
                                      }
                                    </span>
                                    <span style={{ direction: 'ltr', display: 'flex', flexDirection: 'row', fontSize: '15px' }}>
                                      #{doc.Download_Number}
                                    </span>
                                  </h2>

                                  <h2 style={{
                                    fontSize: '1rem', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                                    textAlign: 'center', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                  }}
                                  >
                                    <span style={{ color: darkMode === 'light' ? '#000000' : '#FFFFFF', margin: '0 10px' }}>
                                      {CurrentLanguage === 'EN'
                                        ? 'Genres :'
                                        : 'التصنيفات :'
                                      }
                                    </span>
                                    <h2 style={{
                                      fontSize: '14.3px', color: '#3993B6', borderRadius: '0.2rem', flexWrap: 'wrap', width: 'auto', paddingLeft: '18px',
                                      direction: 'ltr'
                                    }}
                                    >{doc.Download_Genres.map((genre) => genre + ', ')}
                                    </h2>
                                  </h2>
                                </div>

                              </div>
                              : null}


                            {!isNonMobile ?
                              <div style={{
                                width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                textAlign: 'center', marginTop: '10px'
                              }}>
                                <LazyLoadImage
                                  src={doc.Download_Picture}
                                  alt={doc.Download_Name}
                                  effect="blur"
                                  style={{ width: '100%', height: '100%', maxHeight: '220px', borderRadius: '15px', }}
                                />
                                <div style={{
                                  display: 'flex', flexDirection: isTablet ? 'row' : 'column', marginBottom: '28px', marginTop: '16px',
                                  justifyContent: 'center', alignItems: 'center'
                                }}>
                                  <Button
                                    variant='contained'
                                    style={{
                                      color: darkMode === 'light' ? '#000000' : '#ffffff', borderRadius: '1rem', backgroundColor: '#0000003A',
                                      justifyContent: 'center', fontSize: '15px', marginBottom: isTablet ? null : '30px',
                                    }}
                                    onClick={() => {
                                      // delete the game from the user downloads
                                      setTimeout(() => {
                                        database
                                          .collection('users')
                                          .doc(firebase.auth().currentUser.email)
                                          .collection('more-details')
                                          .doc('User_History')
                                          .collection('User_Downloads')
                                          .doc(doc.Download_ID)
                                          .delete()
                                      }, 500)
                                    }}
                                  >
                                    <Trash3 style={{
                                      marginLeft: CurrentLanguage === 'EN' ? null : '5px', marginRight: CurrentLanguage === 'EN' ? '5px' : null,
                                      color: '#FF3943', transition: 'all 0.2s ease-in-out'
                                    }} />
                                    {CurrentLanguage === 'EN' ? 'Remove' : 'حذف '}
                                  </Button>

                                  <Button
                                    variant='contained'
                                    style={{
                                      color: 'white', borderRadius: '1rem', backgroundColor: '#2759A5B6',
                                      justifyContent: 'center', fontSize: '13px', marginRight: isTablet ? CurrentLanguage === 'EN' ? null : '20px' : null,
                                      marginLeft: isTablet ? CurrentLanguage === 'EN' ? '15px' : null : null,
                                    }}
                                    onClick={() => {
                                      window.location.href = doc.Download_aType === 'app'
                                        ? `/A2G_apps/${doc.Download_Number}/${doc.Download_Page}`
                                        : doc.Download_aType === 'game' ? `/A2G_games/${doc.Download_Number}/${doc.Download_Page}`
                                          : null
                                    }}
                                  >
                                    <ArrowLeftRight style={{
                                      marginLeft: CurrentLanguage === 'EN' ? null : '5px', marginRight: CurrentLanguage === 'EN' ? '5px' : null,
                                      color: '#FFFFFF', transition: 'all 0.2s ease-in-out'
                                    }} />
                                    {CurrentLanguage === 'EN' ? 'Go to Page' : 'الذهاب للصفحة'}
                                  </Button>
                                </div>

                                <div style={{
                                  width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                                  textAlign: 'center'
                                }}>
                                  <h2 style={{ fontSize: '1rem', color: '#3993B6', borderRadius: '0.2rem' }}
                                  >
                                    <span style={{ color: '#FFFFFF' }}>
                                      {CurrentLanguage === 'EN'
                                        ? doc.Download_aType === 'game' ? 'Game : ' : doc.Download_aType === 'app' ? 'App : ' : null

                                        : doc.Download_aType === 'game' ? 'لعبة : ' : doc.Download_aType === 'app' ? 'برنامج : '
                                          : null
                                      }
                                    </span>
                                    <span style={{ direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', fontSize: '15px' }}>
                                      {doc.Download_Name.length >= 27 ? `${doc.Download_Name.slice(0, 27)}...` : doc.Download_Name}
                                    </span>
                                  </h2>

                                  <h2 style={{
                                    fontSize: '1rem', color: '#3993B6', borderRadius: '0.2rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                                    display: 'flex', flexDirection: 'row', marginBottom: '20px', marginTop: '12px'
                                  }}
                                  >
                                    <span style={{ color: '#FFFFFF', }}>
                                      {CurrentLanguage === 'EN'
                                        ? 'Size : '
                                        : 'الحجم : '
                                      }
                                    </span>
                                    <span style={{ direction: 'ltr', display: 'flex', flexDirection: 'row', fontSize: '15px', margin: '0 5px' }}>
                                      {doc.Download_Size}
                                    </span>
                                  </h2>

                                  <div style={{
                                    width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                                    textAlign: 'center', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', fontSize: '1.1513rem', marginBottom: '12px',
                                  }}>
                                    <span style={{ color: '#FFFFFF', }}>
                                      {CurrentLanguage === 'EN'
                                        ? 'Downloaded At : '
                                        : 'تاريخ التحميل : '
                                      }
                                    </span>
                                    <span style={{
                                      fontSize: '14.5px', color: '#3993B6', borderRadius: '0.2rem', flexWrap: 'wrap', width: 'fit-content',
                                      direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', display: 'flex', flexDirection: 'row'
                                    }}
                                    >
                                      <span style={{ direction: 'ltr', display: 'flex', flexDirection: 'row' }}>
                                        {CurrentLanguage === 'EN'
                                          ? doc.Download_Downloaded_At_Date + ' - ' + doc.Download_Downloaded_At_Time
                                          : doc.Download_Downloaded_At_Time + ' - ' + doc.Download_Downloaded_At_Date
                                        }
                                      </span>
                                      <span>
                                        {doc.Download_Downloaded_At_Date && doc.Download_Downloaded_At_Time ?
                                          <span style={{ color: '#FFFFFF', fontSize: CurrentLanguage === 'EN' ? '15.5px' : '16.5px', margin: '0 5px', }}>
                                            {CurrentLanguage === 'EN'
                                              ? (Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)) === 0)
                                                ? ' ( Today )'
                                                : (Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)) === 1)
                                                  ? ' ( Yesterday )'
                                                  : '( ' + Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)).toLocaleString() + ' days ago)'


                                              : (Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)) === 0)
                                                ? ' ( اليوم )'
                                                : (Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)) === 1)
                                                  ? ' ( أمس )'
                                                  : ' ( منذ ' + Math.floor((new Date() - doc.Download_CreatedAt.toDate()) / (1000 * 60 * 60 * 24)).toLocaleString() + ' يوم )'
                                            }
                                          </span>
                                          : null
                                        }
                                      </span>
                                    </span>
                                  </div>

                                  <h2 style={{
                                    fontSize: '1rem', color: '#3993B6', borderRadius: '0.2rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                                    display: 'flex', flexDirection: 'row', marginBottom: '20px', marginTop: '12px'
                                  }}
                                  >
                                    <span style={{ color: '#FFFFFF', }}>
                                      {CurrentLanguage === 'EN'
                                        ? doc.Download_aType === 'app' ? 'App Number : ' : doc.Download_aType === 'game' ? 'Game Number : ' : null
                                        : doc.Download_aType === 'app' ? 'رقم البرنامج : ' : doc.Download_aType === 'game' ? 'رقم اللعبة : ' : null
                                      }
                                    </span>
                                    <span style={{ direction: 'ltr', display: 'flex', flexDirection: 'row', fontSize: '15px', margin: '0 5px' }}>
                                      #{doc.Download_Number}
                                    </span>
                                  </h2>


                                  <div style={{
                                    width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                                    textAlign: 'center', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', fontSize: '1.1513rem'
                                  }}>
                                    <span style={{ color: '#FFFFFF', marginBottom: '5px' }}>
                                      {CurrentLanguage === 'EN'
                                        ? 'Genres : '
                                        : 'التصنيفات : '
                                      }
                                    </span>
                                    <h2 style={{
                                      fontSize: '13px', color: '#3993B6', borderRadius: '0.2rem', flexWrap: 'wrap', width: 'auto', paddingLeft: '18px',
                                      direction: 'ltr'
                                    }}
                                    >{doc.Download_Genres.map((genre) => genre + ', ')}
                                    </h2>
                                  </div>

                                </div>

                              </div>
                              : null}

                          </div>

                          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{
                              width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
                              direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                            }}>
                              <div style={{
                                fontSize: '1.1513rem', color: darkMode === 'light' ? '#000000' : '#ffffff', borderRadius: '0.2rem', display: 'flex',
                                justifyContent: 'right', textAlign: 'right', margin: '0 10px'
                              }}>
                                {CurrentLanguage === 'EN'
                                  ? doc.Download_aType === 'game' ? 'Game Description :' : doc.Download_aType === 'app' ? 'App Description :' : 'Description :'
                                  : doc.Download_aType === 'game' ? 'وصف اللعبة :' : doc.Download_aType === 'app' ? 'وصف البرنامج :' : 'الوصف :'
                                }
                              </div>
                              <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                defaultValue={doc?.Download_Description
                                  ? doc?.Download_Description.length > 550
                                    ? processDescription(doc?.Download_Description.slice(0, 550)).concat('...')
                                    : processDescription(doc?.Download_Description) : ''}
                                style={{
                                  width: '100%', color: darkMode === 'light' ? '#000000' : '#ffffff', 
                                  borderRadius: '0.2rem', 
                                  backgroundColor: darkMode === 'light' ? '#F0F0F04D' : '#B8B8B80C',
                                  direction: 'rtl', textAlign: 'right'
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                sx={{ gridColumn: "span 4" }}
                                inputProps={{
                                  style: {
                                    textAlign: 'left',
                                    direction: 'rtl',
                                    color: darkMode === 'light' ? '#000000' : '#A5A5A5',
                                  }
                                }}
                              />

                            </div>
                          </div>
                        </div>
                      )
                    })
                }

                {gamesWrapper < User_Downloads.length && Stop_LoadMoreGames === false ?
                  <div
                    style={{
                      textDecoration: 'none',
                      cursor: 'pointer',
                      width: '150%',
                      height: '100%',
                      padding: '10px',
                      borderRadius: '25px',
                      border: 'none',
                      outline: 'none',
                      fontSize: '1.2rem',
                      marginBottom: '0px',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#fff',
                    }}
                  >
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {isVisible ? LoadingMoreGames() : null}
                          <span style={{ opacity: '0' }}>.</span>
                          {LoadMoreGames === true ?
                            <LazyLoadImage
                              effect="blur"
                              draggable={false}
                              src={Profile_Loading}
                              alt='Profile_Loading'
                              style={{ width: '50px', height: '50px', display: 'flex' }} />
                            :
                            null
                          }
                        </div>
                      }
                    </TrackVisibility>
                  </div>
                  :
                  null
                }
              </div>

            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default DownloadPage